@tailwind base;
@tailwind components;
@tailwind utilities;


.loader {
  /* position: absolute;
  top: calc(50% - 73px);
  left: calc(50% - 73px); */
  width: 150px;
  height: 150px;
  border-radius: 50%;
  perspective: 900px;
}

.inner {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border-radius: 50%;  
}

.inner.one {
  left: 0%;
  top: 0%;
  animation: rotate-one 1s linear infinite;
  border-bottom: 5px solid #F9BB80;
}

.inner.two {
  right: 0%;
  top: 0%;
  animation: rotate-two 1s linear infinite;
  border-right: 5px solid #FF013C;
}

.inner.three {
  right: 0%;
  bottom: 0%;
  animation: rotate-three 1s linear infinite;
  border-top: 5px solid #00E6F6;
}

@keyframes rotate-one {
  0% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
  }
}

@keyframes rotate-two {
  0% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
  }
}

@keyframes rotate-three {
  0% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
  }
}

@media (max-width: 600px) {
  .loader {
    /* top: calc(50% - 65px);
    left: calc(50% - 65px); */
    width: 130px;
    height: 130px;
  }
}







/* ---------------------------    scroll bar      --------------------------- */

/* width */
::-webkit-scrollbar {
    width: 8px;
}
  
/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}
  
/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
}
  
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}


/* ---------------------------   More About Me   --------------------------- */
.button-89 {
  --b: 2px;   /* border thickness */
  --s: .25em; /* size of the corner */
  --color: #373B44;
  
  padding: calc(.5em + var(--s)) calc(.9em + var(--s));
  color: var(--color);
  --_p: var(--s);
  background:
    conic-gradient(from 90deg at var(--b) var(--b),#0000 90deg,var(--color) 0)
    var(--_p) var(--_p)/calc(100% - var(--b) - 2*var(--_p)) calc(100% - var(--b) - 2*var(--_p));
  transition: .3s linear, color 0s, background-color 0s;
  outline: var(--b) solid #0000;
  outline-offset: .6em;

  border: 0;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-89:hover,
.button-89:focus-visible{
  --_p: 0px;
  outline-color: var(--color);
  outline-offset: .05em;
}

.button-89:active {
  background: var(--color);
  color: #fff;
}


/* ---------------------------     CV Download Button    --------------------------- */
.button-82-pushable {
  position: relative;
  border: none;
  background: transparent;
  padding: 0;
  cursor: pointer;
  outline-offset: 4px;
  transition: filter 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-82-edge {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background: linear-gradient(
    to right,
    #503f2f 0%,
    #86674b 8%,
    #a3784f 92%,
    #473626
  );
}

.button-82-front {
  display: block;
  position: relative;
  padding: 8px 16px;
  border-radius: 12px;
  color: black;
  background: #F9BB80;
  will-change: transform;
  transform: translateY(-4px);
  transition:
    transform
    600ms
    cubic-bezier(.3, .7, .4, 1);
}

@media (min-width: 640px) {
  .button-82-front {
    padding: 10px 18px;
  }
}

@media (min-width: 768px) {
  .button-82-front {
    padding: 11px 19px;
  }
}

@media (min-width: 1024px) {
  .button-82-front {
    padding: 12px 20px;
  }
}

.button-82-pushable:hover {
  filter: brightness(110%);
  -webkit-filter: brightness(110%);
}

.button-82-pushable:hover .button-82-front {
  transform: translateY(-6px);
  transition:
    transform
    250ms
    cubic-bezier(.3, .7, .4, 1.5);
}

.button-82-pushable:active .button-82-front {
  transform: translateY(-2px);
  transition: transform 34ms;
}

.button-82-pushable:hover .button-82-shadow {
  transform: translateY(4px);
  transition:
    transform
    250ms
    cubic-bezier(.3, .7, .4, 1.5);
}

.button-82-pushable:active .button-82-shadow {
  transform: translateY(1px);
  transition: transform 34ms;
}

.button-82-pushable:focus:not(:focus-visible) {
  outline: none;
}




/* ---------------------------    MY GITHUB BUTTON     --------------------------- */
.button-49,
.button-49:after {
  width: 170px;
  height: 35px;
  line-height: 20px;
  font-size: 1rem;
  font-family: 'Bebas Neue', sans-serif;
  background: linear-gradient(45deg, transparent 5%, #FF013C 5%);
  border: 0;
  color: #fff;
  letter-spacing: 3px;
  box-shadow: 6px 0px 0px #00E6F6;
  outline: transparent;
  position: relative;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-49:after {
  --slice-0: inset(50% 50% 50% 50%);
  --slice-1: inset(80% -6px 0 0);
  --slice-2: inset(50% -6px 30% 0);
  --slice-3: inset(10% -6px 85% 0);
  --slice-4: inset(40% -6px 43% 0);
  --slice-5: inset(80% -6px 5% 0);
  
  content: 'ALTERNATE TEXT';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(45deg, transparent 3%, #00E6F6 3%, #00E6F6 5%, #FF013C 5%);
  text-shadow: -3px -3px 0px #F8F005, 3px 3px 0px #00E6F6;
  clip-path: var(--slice-0);
}

.button-49:hover:after {
  animation: 1s glitch;
  animation-timing-function: steps(2, end);
}

@keyframes glitch {
  0% {
    clip-path: var(--slice-1);
    transform: translate(-20px, -10px);
  }
  10% {
    clip-path: var(--slice-3);
    transform: translate(10px, 10px);
  }
  20% {
    clip-path: var(--slice-1);
    transform: translate(-10px, 10px);
  }
  30% {
    clip-path: var(--slice-3);
    transform: translate(0px, 5px);
  }
  40% {
    clip-path: var(--slice-2);
    transform: translate(-5px, 0px);
  }
  50% {
    clip-path: var(--slice-3);
    transform: translate(5px, 0px);
  }
  60% {
    clip-path: var(--slice-4);
    transform: translate(5px, 10px);
  }
  70% {
    clip-path: var(--slice-2);
    transform: translate(-10px, 10px);
  }
  80% {
    clip-path: var(--slice-5);
    transform: translate(20px, -10px);
  }
  90% {
    clip-path: var(--slice-1);
    transform: translate(-10px, 0px);
  }
  100% {
    clip-path: var(--slice-1);
    transform: translate(0);
  }
}

@media (min-width: 640px) {
  .button-49,
  .button-49:after {
    font-size: .95rem;
    width: 140px;
    height: 35px;
    line-height: 15px;
  }
}

@media (min-width: 768px) {
  .button-49,
  .button-49:after {
    font-size: 1rem;
    width: 140px;
    height: 35px;
    line-height: 15px;
  }
}

@media (min-width: 1024px) {
  .button-49,
  .button-49:after {
    width: 155px;
    height: 40px;
    line-height: 20px;
  }
};



/* -------------------------- Project Technologies slider ------------------------- */

.custom-container {
  width: 70%;
}

.custom-wrapper {
  display: flex;
  max-width: 500px;
  overflow-x: hidden;
}

.custom-slide {
  width: auto;
}

.custom-bullet {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: gray;
}

.custom-bullet-active {
  background-color: yellow;
}


/* ------------------------------------------ contact me send button ------------------------------ */

/* CSS */
.button-30 {
  align-items: center;
  appearance: none;
  border-radius: 4px;
  border-width: 0;
  /* box-shadow: rgba(45, 35, 66, 0.4) 0 -2px 4px,rgba(45, 35, 66, 0.3) 0 7px 13px -3px,#D6D6E7 0 -3px 0 inset; */
  box-sizing: border-box;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  list-style: none;
  overflow: hidden;
  position: relative;
  text-align: left;
  text-decoration: none;
  transition: box-shadow .15s,transform .15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow,transform;
}

.button-30:focus {
  /* box-shadow: #D6D6E7 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset; */
}

.button-30:hover {
  /* box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset; */
  transform: translateY(-2px);
}

.button-30:active {
  /* box-shadow: #D6D6E7 0 3px 7px inset; */
  transform: translateY(2px);
}
















/* ------------------------------------------ Show more project button ------------------------------ */

/* CSS */
.button-74 {
  background-color: #fbeee0;
  border: 1px solid #422800;
  border-radius: 30px;
  /* box-shadow: #242424 4px 4px 0 0; */
  color: #422800;
  cursor: pointer;
  display: inline-block;
  font-weight: 600;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-74:hover {
  background-color: #fff;
}

.button-74:active {
  box-shadow: #422800 2px 2px 0 0;
  transform: translate(2px, 2px);
}




