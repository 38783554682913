@font-face {
  font-family: 'logo';
  src: url('./bacasime/BacasimeAntique-Regular.ttf') format('truetype');
    /* Add other font formats if needed, like woff, woff2, etc. */
}

@font-face {
  font-family: 'para';
  src: url('./cormorant/CormorantGaramond-SemiBold.ttf') format('truetype');
    /* Add other font formats if needed, like woff, woff2, etc. */
}